<template>
  <static-fullscreen-card :loaded="loaded">
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-send-code v-if="id && !readonly && data.status === 0" :id="id" :api="'/accounting/doc/act_work'" @success="fetchData(id)" :title="'Подпись(SMS)'" />
      <v-tooltip bottom v-if="hintStatus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
        </template>
        <span>{{ hintStatus }}</span>
      </v-tooltip>
      <v-btn v-if="data.status == 2 && data.print_act" :href="$root.config.fileUrl + data.print_act" target="_blank" class="mx-1" dark color="info" title="Скачать">
        <v-icon dark>fas fa-file-signature </v-icon>
      </v-btn>
      <v-btn v-else-if="data.id && data.contract_id" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="Предпросмотр">
        <v-icon dark>fas fa-eye </v-icon>
      </v-btn>

      <a-btn-status
        v-if="getAccess('documents.actWork.status', { users: [data.createdby_id] })"
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="[0, 1].includes(data.status) && accessEdit" @click="showEditDialog = true" :disabled="!canEdit" />
      <a-btn-delete
        v-if="id && data.status === 0 && getAccess('documents.actWork.delete', { users: [data.createdby_id] })"
        :disabled="!canDelete"
        @click="removeDialogShow = true"
      />
    </template>
    <a-loader v-if="!loaded" />
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: !true }" @click="onClick($event)" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" :style1="{ height: blockHeight + 'px' }" @click="getBalance()" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <s-tabs-cash v-if="t.name == 'cash'" :data="data" type="out" :canAdd="data.status == 2 && accessEdit" :m="m" :height="detailHeight" />

              <v-card-text v-if="t.name == 'files'" :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('doc_all')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
              <a-view-images v-if="t.name == 'photos'" :value="data.photos" :style="styleDetailCard" />
              <v-card-text v-if="t.name == 'account'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="accDocName" :join="{ AccountingDocOrderWithdrawalModel: { parent_name: m.accDocName, parent_id: id } }" />
              </v-card-text>
              <s-tabs-status-history v-if="t.name == 'statusHistory'" :data="data.status_history" :m="m" :height="detailHeight" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <EditDialog v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :id="id" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
    </portal>
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <s-editor-editor
      v-if="getAccess('suData')"
      ref="suEdit"
      v-model="suEditShow"
      :config="{ table: 'accounting_doc_act_work', filter: { id } }"
      @show="suEditShow = true"
      :activate="(id || 0) > 0"
    />
    <confirm-dialog ref="confirm" />
  </static-fullscreen-card>
</template>

<script>
import cash from "@/components/s/document/cash";
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, cash],
  props: {
    idShow: Number,
  },
  components: {
    SelectDialog: () => import("./../dialogs/cashOperationSelectDialog"),
    CashDialog: () => import("./../dialogs/cashDialog"),
    EditDialog: () => import("./../dialogs/actWorkDialog"),
    //   editDialog: () => import("./../dialogs/objectSellDialog"),
  },
  data() {
    return {
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      suEditShow: false,
      showEditDialog: false,
      showCashSelectDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      configHead: null,
      m: this.$store.getters["config/get"].models.documentActWork,
      tab: 0,
      tabs: [
        { name: "files", title: "Документы", show: true },
        { name: "photos", title: "Галерея", show: true },
        { name: "cash", title: "Оплата", show: true },
        { name: "account", title: "Проводки", show: this.getAccess("documents.actWork.accounting") },
        { name: "statusHistory", title: "История статусов", show: this.getAccess("documents.actWork.statusHistory") },
      ],
      hintStatus: null,

      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
  },
  mounted() {},

  computed: {
    readonly() {
      return this.data.status !== 0;
    },
    accDocName() {
      return this.m.accDocName;
    },
    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },
    statuses() {
      return this.m.statuses;
    },
    accDocName() {
      return this.m.accDocName;
    },
    accessEdit() {
      return this.getAccess("documents.actWork.edit", { users: [this.data.createdby_id] });
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      this.hintStatus = null;
      if (this.data.status == 1) {
        if (!this.data.contract_id) {
          this.hintStatus = "Не выбран контракт";
          return true;
        }
        if (!(this.data.value_penalty === 0 || this.data.value_penalty > 0)) {
          this.hintStatus = "Не указан размер пени";

          return true;
        }
      }

      return (this.data.data_table && this.data.data_table.length == 0) || this.loading;
    },
    canEdit() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    canDelete() {
      if (this.data.data_table && this.data.data_table.length) return false;
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let type = this.data.operation_type || "default";
        let config = JSON.parse(JSON.stringify(this.m?.config?.[type] || this.m?.config.default || {}));
        this.configHead = config;
        let model = this.calcModelConfig(config);
        return model;
      },
    },
    statusItems() {
      if (!this.data.status && this.data.status !== 0) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      //res = res.filter((s) => this.getAccess("documents.actWork.status." + s.value, null, true));
      res.forEach((s) => {
        if (!this.getAccess("documents.actWork.status." + s.value, null, true)) s.disabled = true;
      });
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },

    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    getPreview() {
      return this.$root.config.baseUrl + `/accounting/doc/act_work/print_doc/${this.id}?token=` + localStorage.getItem("user-token");
    },
    onClick(d) {
      if (d.field?.isMenu) {
        if (d.field.name == "object_code") {
          let name = d.row.type == 1 ? "object" : d.row.type == 2 ? "office" : "storage";

          this.showPopupMenu(name, { id: d.row.object_code }, d.event);
        } else this.showPopupMenu(d.field.name, d.row, d.event);
      }
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.addDocModel["api"] = this.api;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$router.push({ name: "actWork" });
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    async changeStatus(status) {
      let ok = false;

      if (status.value == 2) {
        let confirm = await this.$refs.confirm.show({
          title: "Обратите внимание!",
          message: [
            "Данным действием вы подписываете акт выполненных работ от подрядчика и принимаете работы. После проводки акта будет создана автоматическая задача на оплату работ по акту.",
          ],
          okButton: "Продолжить",
          cancelButton: "Отмена",
        });
        if (!confirm) return;
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      try {
        let response = await this.$axios.post(this.api, data);
        this.loading = false;
        status = response.data.status == "ok";
        if (response.data.data) {
          Object.assign(this.data, response.data.data);
          this.$root.$emit("show-info", {
            text: "Статус изменен",
          });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при изменении статуса",
        });
      }
    },
    addRecord() {
      if (this.data.operation_type == 60 || true) {
        this.idEdit = 0;
        this.showDialogEditDetail = true;
        return;
      }
    },
  },
};
</script>
